.footer-container {
  background-image: url("../images/Rectangle87.png"), url("../images/Rectangle3footer.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 1232px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.footer-tiles-section {
  margin-top: -400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-tiles-row {
  width: 90%;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-tiles-row-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-tiles-row-title h4 {
  font-size: 1.8rem;
  font-family: Raleway;
  font-weight: 500;
  letter-spacing: 2px;
}
.footer-tiles-row-tiles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.footer-tiles-row-tile {
  width: 30%;
  aspect-ratio: 1.5 / 1;
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 40px;
  -webkit-box-shadow: 0px 0px 13px 1px rgba(103, 103, 103, 1);
  -moz-box-shadow: 0px 0px 13px 1px rgba(103, 103, 103, 1);
  box-shadow: 0px 0px 13px 1px rgba(103, 103, 103, 1);
}
.footer-tiles-row-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  color: white;
  font-size: 4rem;
  filter: brightness(90%);
}
.icon-red {
  background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.icon-lightblue {
  background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.icon-blue {
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);

}
.footer-tiles-row-tile p {
  margin: 30px;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1rem;
}
.footer-sponsors-row {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sporsor1-img {
  height: 80px;
  width: auto;
  padding: 15px;
  background-color: white;
}
.sporsor2-img {
  height: 80px;
  width: auto;
}
.footer-bottom {
  padding: 40px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.social {
  text-align: center;
}
.social a {
  font-size: 2rem;
  font-family: Raleway;
  font-weight: 500;
  color: white;
  text-decoration: none;
}
.social a:hover {
  cursor: pointer;
  color: lightslategray;
}
.copyright {
  font-size: 0.7rem;
  font-family: Raleway;
  font-weight: 300;
  letter-spacing: 1px;
  color: white;
}

@media screen and (max-width: 1100px) {
  .footer-container {
    background-size: cover;
  }
  .footer-tiles-section {
    margin-top: -80px;
  }
  .footer-tiles-row-tiles {
    flex-direction: column;
  }
  .footer-tiles-row-tile {
    width: 80%;
  }
  .footer-sponsors-row {
    flex-direction: column;
    justify-content: space-around;
  }
  .footer-sponsors-row img {
    margin: 20px auto;
  }
}
@media screen and (max-width: 768px) {
  .footer-tiles-row-tiles {
    flex-direction: column;
    width: 100%;
  }
}
