.hero-container {
  background-image: url("../images/Rectangle3.png"), url("../images/Rectangle1.png");
  background-repeat: no-repeat;
  background-position: 50% -50%;
  height: 1232px;
  width: 100%;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  object-fit: contain;
}

.komunikat {
  position: absolute;
  top: 450px;
  z-index: 99;
  background-color: beige;
  border: 4px dashed palevioletred;
  width: 50%;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: darkred;
}

.komunikat2 {
  z-index: 99;
  background-color: beige;
  border: 4px dashed palevioletred;
  width: 50%;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: darkred;
}

.h1-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20%;
}

.h1-section-wrapper h1 {
  color: #fff;
  font-size: 50px;
  font-family: Raleway;
  font-weight: 700;
  margin-top: -100px;
}

.h1-section-wrapper p {
  margin-top: 8px;
  color: #fff;
  font-size: 28px;
  font-family: Raleway;
  font-weight: 500;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
  width: 200px;
  height: 50px;
  font-family: Raleway;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 50px;
}

.fa-play-circle {
  margin-left: 4px;
}

.hero-tiles-wrapper {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 850px;
}

.reservation-btn {
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px 30px;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-family: Raleway;
}

.hero-tile {
  background-color: whitesmoke;
  width: 30%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 4px 4px 50px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.hero-icon-circle {
  background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
  width: 162px;
  height: 162px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: white;
  margin-top: 50px;
}

.hero-tile-text {
  margin: 30px 0;
  width: 70%;
}

.hero-tile-text > h2 {
  font-family: Raleway;
  font-weight: 500;
}

.hero-tile-text > p {
  margin-top: 10px;
  font-family: Raleway;
  font-weight: 400;
}

@media screen and (max-width: 1100px) {
  .h1-section-wrapper > h1 {
    font-size: 4rem;
    margin: 50px;
    text-align: center;
  }
  .hero-tiles-wrapper {
    width: 100%;
    padding: 20px;
  }
  .hero-tile {
    height: 400px;
    margin: 10px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 800px;
    background-position: 50%;
    margin-bottom: 80px;
    padding-top: 10%;
  }
  .h1-section-wrapper {
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    padding: 10px;
  }
  .h1-section-wrapper h1 {
    font-size: 2rem;
  }
  .h1-section-wrapper p {
    font-size: 1rem;
  }
  .hero-tiles-wrapper {
    padding: 0;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    top: 450px;
  }
  .hero-tile {
    aspect-ratio: 1 / 1;
    width: 42%;
    height: auto;
    margin: 10px;
  }
  .hero-icon-circle {
    font-size: 1.5rem;
    width: 35%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin-top: 20px;
  }
  .hero-tile-text {
    font-size: 0.7rem;
    margin: 10px 0;
    width: 100%;
    padding: 5px;
  }
  .hero-tile-text h2 {
    font-size: 0.8rem;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
