.aboutus-section-wrapper {
    background-color: #B7DDFD;
    padding: 50px;
    margin: -1px;
}
.wavesbottom {
    width: 100%;
}
.wavestop {
    background: #D8ECFF;
    width: 100%;
}
.wavestop svg{
    margin-bottom: -5px;
    transform:scale(-1,1);
}
.aboutus-title {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.aboutus-title h3 {
    color: #003A6E;
    font-size: 2rem;
    font-weight: 600;
    font-family: Raleway;
}

.aboutus-description {
    color: #003A6E;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: Raleway;
    width: 60%;
    margin: 70px auto;
    text-align: center;
    line-height: 2rem;
}

.aboutus-tiles-wrapper {
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
}
.aboutus-tiles {
    width: 100%;
    height: 50vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.aboutus-tile_first-section {
    border: none;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
    text-align: center;
    font-size: 5rem;
}

.aboutus-tile-photo {
    width: 50%;
    height: auto;
}

.aboutus-tile-big {
    font-family: Raleway;
    font-weight: 800;
    font-size: 2rem;
}

.aboutus-tile-text {
    margin-top: 20px;
    font-family: Raleway;
    font-weight: 600;
    font-size: 1rem;
    width: 60%;
}

.aboutus-tile-1 {
    grid-area: 1 / 1 / 2 / 3;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tile-2 {
    grid-area: 1 / 3 / 2 / 5;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-3 {
    grid-area: 2 / 1 / 4 / 3;
    background-image: url("../images/tilephoto1.jpeg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.aboutus-tile-4 {
    grid-area: 2 / 3 / 3 / 4;
    background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.aboutus-tile-5 {
    grid-area: 2 / 4 / 3 / 5;
    background: linear-gradient(180deg, #0376DF 0%, #004687 100%);
}
.aboutus-tile-6 {
    grid-area: 3 / 3 / 4 / 4;
    background: linear-gradient(180deg, #969595 0%, #656565 100%);
}
.aboutus-tile-7 {
    grid-area: 4 / 1 / 5 / 2;
    background: linear-gradient(180deg, #404040 0%, #1E1D1D 100%);
}
.aboutus-tile-8 {
    grid-area: 4 / 2 / 5 / 4;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-9 {
    grid-area: 3 / 4 / 5 / 5;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tiles-second {
    width: 100%;
    height: 40vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.aboutus-tile-second1 {
    grid-area: 1 / 1 / 3 / 2;
    background: linear-gradient(180deg, #404040 0%, #1E1D1D 100%);
}
.aboutus-tile-second2 {
    grid-area: 3 / 1 / 4 / 2;
    background: linear-gradient(180deg, #969595 0%, #656565 100%);
}
.aboutus-tile-second3 {
    grid-area: 1 / 2 / 2 / 3;
    background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.aboutus-tile-second4 {
    grid-area: 2 / 2 / 4 / 3;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tile-second5 {
    grid-area: 3 / 3 / 4 / 5;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-second6 {
    grid-area: 1 / 3 / 3 / 5;
    background-image: url("../images/tilephoto2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 1100px) {
    .aboutus-section-wrapper {
        width: 100%;
        padding: 10px;
    }
    .aboutus-description {
        width: 100%;
    }
    .aboutus-tiles-wrapper {
        width: 100%;
    }
    .aboutus-tiles {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    .aboutus-tiles-second {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    .aboutus-tile_first-section {
        font-size: 1.5rem;
        padding: 10px;
    }
    .aboutus-tile-text {
        font-size: 0.7rem;
        width: 100%;
    }
}