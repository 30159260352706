@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap);
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.reservation {
  padding: 4rem;
  background: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.reservation > h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.reservation-tabs-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 20px;
}

.reservation-tab {
  width: 48%;
  border: 3px solid #0056A4;
  border-radius: 100px;
  padding: 10px 30px;
  font-size: 1rem;
  font-family: Raleway;
  font-weight: 600;
  color: #0056A4;
  transition: 0.4s;
}

.reservation-tab:hover {
  cursor: pointer;
  background-color: #003A6E;
  border-color: #003A6E;
  color: white;
}

.reservation-active {
  background: #0056A4;
  color: white;
}

.reservation-form-wrapper {
  width: 90%;
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
}

.reservation-form-wrapper-disabled {
  display: none;
}

.reservation-form-wrapper form {
  width: 100%;
  letter-spacing: 1px;
}
.reservation-form-wrapper h3 {
  font-weight: 400;
  font-family: Raleway;
}

.reservation-form-row-alt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px;
}

.reservation-form-row {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.reservation-double-checkbox {
  width: 50%;
  text-align: left;
  padding: 10px 20px;
}
.reservation-double-checkbox label {
  font-size: 0.8rem;
}

.reservation-form-text-input, .reservation-form-select-payment {
  width: 100%;
  height: 40px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-family: Raleway;
  font-weight: 600;
}
.reservation-form-text-input-alt {
  width: 60%;
  margin-right: 20px;
  height: 40px;
  border: none;
  padding: 5px;
  border-radius: 5px;
}
.checkbox-flex-column {
  flex-direction: column;
  width: 100%;
  text-wrap: nowrap;
}
.reservation-form-info-text {
  color: #B7DDFD;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 300;
  font-family: Raleway;
  text-decoration: underline;
}
.reservation-form-check-label {
  font-size: 0.8rem;
  font-weight: 300;
  font-family: Raleway;
  margin-left: 10px;
}
.reservation-form-accept-label {
  font-size: 1.2rem!important;
  font-weight: 300;
  font-family: Raleway;
  margin-left: 10px;
}
#reservation-form-accept {
  transform: scale(1.5);
  margin-left: 5px;
}
.reservation-form-accept-label a {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Raleway;
}
.reservation-form-submit {
  padding: 10px 30px;
  margin: auto;
  border: 2px solid whitesmoke;
  border-radius: 50px;
  background-color: #940000;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Raleway;
  font-size: 1.4rem;
  letter-spacing: 1px;
}
.reservation-form-submit:hover {
  cursor: pointer;
  background-color: #7e0004;
}
.reservation-additional-info-disabled {
  display: none;
}
.reservation-additional-info {
  display: block;
}
.reservation-input-label {
  padding: 0 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.reservation-input-label-full {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.reservation-input-label label {
  padding: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: Raleway;
  text-wrap: nowrap;
}
.reservation-input-label-full label {
  padding: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: Raleway;
  text-wrap: nowrap;
}

#reservation-response {
  display: none;
  width: 90%;
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
  padding: 50px 20px;
  border-radius: 15px;
  color: white;
}
#response-title {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Raleway;
}
#response-description {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 400;
  font-family: Raleway;
}
#response-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
#voucher-link {
  color: white;
  text-decoration: none;
}
.voucher-active {
  display: block;
}
.voucher-disabled {
  display: none;
}
.response-button {
  margin: 20px;
  border: 2px solid whitesmoke;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: Raleway;
  padding: 10px 30px;
  border-radius: 50px;
}
.response-button:hover {
  cursor: pointer;
}
#download-voucher {
  background-color: #940000;
}
#download-voucher:hover {
  background-color: #7E0004;
}
#refresh-reservation:hover {
  background-color: #003A6E;
}

.download-empty-voucher {
  position: relative;
  top: 50px;
  left: 290px;
}

.download-empty-voucher a {
  color: black;
}


@media screen and (max-width: 1100px) {
  .reservation {
    width: 100%;
    padding: 1rem;
  }
  .reservation-tab {
    font-size: 0.7rem;
    text-align: center;
  }
  .reservation-form-row {
     flex-direction: column;
  }
  .reservation-form-row-alt {
    flex-direction: column;
  }
  .reservation-input-label {
    width: 100%;
  }
  .reservation-form-text-input-alt {
    width: 100%;
  }
  .reservation-form-accept-label {
    font-size: 0.8rem;
  }
  .reservation-form-accept-label a {
    font-size: 0.8rem;
  }
  .reservation-form-row-regulamin {
    flex-direction: row!important;
  }
  .reservation-double-checkbox {
    width: 100%;
  }
  .reservation-double-checkbox label {
    font-size: 0.6rem;
  }
}
.hero-container {
  background-image: url(../../static/media/Rectangle3.8aa7132f.png), url(../../static/media/Rectangle1.2092ec21.png);
  background-repeat: no-repeat;
  background-position: 50% -50%;
  height: 1232px;
  width: 100%;
  padding-top: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  object-fit: contain;
}

.komunikat {
  position: absolute;
  top: 450px;
  z-index: 99;
  background-color: beige;
  border: 4px dashed palevioletred;
  width: 50%;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: darkred;
}

.komunikat2 {
  z-index: 99;
  background-color: beige;
  border: 4px dashed palevioletred;
  width: 50%;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: darkred;
}

.h1-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20%;
}

.h1-section-wrapper h1 {
  color: #fff;
  font-size: 50px;
  font-family: Raleway;
  font-weight: 700;
  margin-top: -100px;
}

.h1-section-wrapper p {
  margin-top: 8px;
  color: #fff;
  font-size: 28px;
  font-family: Raleway;
  font-weight: 500;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
  width: 200px;
  height: 50px;
  font-family: Raleway;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 50px;
}

.fa-play-circle {
  margin-left: 4px;
}

.hero-tiles-wrapper {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 850px;
}

.reservation-btn {
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px 30px;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-family: Raleway;
}

.hero-tile {
  background-color: whitesmoke;
  width: 30%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 4px 4px 50px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.hero-icon-circle {
  background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
  width: 162px;
  height: 162px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: white;
  margin-top: 50px;
}

.hero-tile-text {
  margin: 30px 0;
  width: 70%;
}

.hero-tile-text > h2 {
  font-family: Raleway;
  font-weight: 500;
}

.hero-tile-text > p {
  margin-top: 10px;
  font-family: Raleway;
  font-weight: 400;
}

@media screen and (max-width: 1100px) {
  .h1-section-wrapper > h1 {
    font-size: 4rem;
    margin: 50px;
    text-align: center;
  }
  .hero-tiles-wrapper {
    width: 100%;
    padding: 20px;
  }
  .hero-tile {
    height: 400px;
    margin: 10px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 800px;
    background-position: 50%;
    margin-bottom: 80px;
    padding-top: 10%;
  }
  .h1-section-wrapper {
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    overflow: visible;
    padding: 10px;
  }
  .h1-section-wrapper h1 {
    font-size: 2rem;
  }
  .h1-section-wrapper p {
    font-size: 1rem;
  }
  .hero-tiles-wrapper {
    padding: 0;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    top: 450px;
  }
  .hero-tile {
    aspect-ratio: 1 / 1;
    width: 42%;
    height: auto;
    margin: 10px;
  }
  .hero-icon-circle {
    font-size: 1.5rem;
    width: 35%;
    height: auto;
    aspect-ratio: 1 / 1;
    margin-top: 20px;
  }
  .hero-tile-text {
    font-size: 0.7rem;
    margin: 10px 0;
    width: 100%;
    padding: 5px;
  }
  .hero-tile-text h2 {
    font-size: 0.8rem;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.footer-container {
  background-image: url(../../static/media/Rectangle87.d71bd206.png), url(../../static/media/Rectangle3footer.6702e74a.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 1232px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.footer-tiles-section {
  margin-top: -400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-tiles-row {
  width: 90%;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-tiles-row-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.footer-tiles-row-title h4 {
  font-size: 1.8rem;
  font-family: Raleway;
  font-weight: 500;
  letter-spacing: 2px;
}
.footer-tiles-row-tiles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.footer-tiles-row-tile {
  width: 30%;
  aspect-ratio: 1.5 / 1;
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 40px;
  box-shadow: 0px 0px 13px 1px rgba(103, 103, 103, 1);
}
.footer-tiles-row-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  color: white;
  font-size: 4rem;
  filter: brightness(90%);
}
.icon-red {
  background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.icon-lightblue {
  background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.icon-blue {
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);

}
.footer-tiles-row-tile p {
  margin: 30px;
  font-family: Raleway;
  font-weight: 400;
  font-size: 1rem;
}
.footer-sponsors-row {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sporsor1-img {
  height: 80px;
  width: auto;
  padding: 15px;
  background-color: white;
}
.sporsor2-img {
  height: 80px;
  width: auto;
}
.footer-bottom {
  padding: 40px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.social {
  text-align: center;
}
.social a {
  font-size: 2rem;
  font-family: Raleway;
  font-weight: 500;
  color: white;
  text-decoration: none;
}
.social a:hover {
  cursor: pointer;
  color: lightslategray;
}
.copyright {
  font-size: 0.7rem;
  font-family: Raleway;
  font-weight: 300;
  letter-spacing: 1px;
  color: white;
}

@media screen and (max-width: 1100px) {
  .footer-container {
    background-size: cover;
  }
  .footer-tiles-section {
    margin-top: -80px;
  }
  .footer-tiles-row-tiles {
    flex-direction: column;
  }
  .footer-tiles-row-tile {
    width: 80%;
  }
  .footer-sponsors-row {
    flex-direction: column;
    justify-content: space-around;
  }
  .footer-sponsors-row img {
    margin: 20px auto;
  }
}
@media screen and (max-width: 768px) {
  .footer-tiles-row-tiles {
    flex-direction: column;
    width: 100%;
  }
}

.prices-wrapper {
    padding: 50px;
    background: #D8ECFF;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
}
.wavestop-prices {
    background: #FFFFFF;
    width: 100%;
}
.wavestop-prices svg{
    margin-bottom: -5px;
    transform:scale(-1,1);}
.prices-title {
    width: 100%;
    text-align: center;
}
.prices-title h3 {
    color: #003A6E;
    font-size: 2rem;
    font-weight: 600;
    font-family: Raleway;
}
.prices-table {
    padding: 20px;
    color: white;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
    width: 60%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
}
.prices-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 5px auto;
}
.prices-item {
    padding: 10px;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid whitesmoke;
    border-radius: 50px;
}
.prices-highlight {
    border: 2px solid white;
    font-weight: bold;
}
.prices-item div {
    font-family: Raleway;
}
.prices-row-alt {
    padding: 10px;
    width: 90%;
    margin: 5px auto;
    border: 1px solid whitesmoke;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
}
.prices-item-alt {
    font-weight: 400;
    font-family: Raleway;
}
.prices-description {
    width: 80%;
    text-align: center;
    margin: auto;
}
.prices-description-bold {
    font-weight: 600;
    font-family: Raleway;
    font-size: 1.5rem;
    color: #003A6E;
}
.prices-description-regular {
    margin: 20px auto;
    font-weight: 400;
    font-family: Raleway;
    font-size: 1.2rem;
    color: #003A6E;
}
.prices-tabs-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 20px auto;
}
.prices-table-disabled {
    display: none;
}
.price-tab {
    width: 32%;
    border: 3px solid #0056A4;
    border-radius: 100px;
    padding: 10px 30px;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 600;
    color: #0056A4;
    transition: 0.4s;
    text-align: center;
}
.price-tab:hover {
    cursor: pointer;
    background-color: #003A6E;
    border-color: #003A6E;
    color: white;
}

.price-active {
    background: #0056A4;
    color: white;
}
@media screen and (max-width: 1100px) {
    .prices-wrapper {
        width: 100%;
        padding: 1rem;
    }
    .prices-table {
        width: 100%;
    }
    .prices-row {
        flex-direction: row;
    }
    .prices-item {
        width: 90%;
        font-size: 0.8rem;
        margin: 0 5px;
    }
}
.aboutus-section-wrapper {
    background-color: #B7DDFD;
    padding: 50px;
    margin: -1px;
}
.wavesbottom {
    width: 100%;
}
.wavestop {
    background: #D8ECFF;
    width: 100%;
}
.wavestop svg{
    margin-bottom: -5px;
    transform:scale(-1,1);
}
.aboutus-title {
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.aboutus-title h3 {
    color: #003A6E;
    font-size: 2rem;
    font-weight: 600;
    font-family: Raleway;
}

.aboutus-description {
    color: #003A6E;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: Raleway;
    width: 60%;
    margin: 70px auto;
    text-align: center;
    line-height: 2rem;
}

.aboutus-tiles-wrapper {
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
}
.aboutus-tiles {
    width: 100%;
    height: 50vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.aboutus-tile_first-section {
    border: none;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
    text-align: center;
    font-size: 5rem;
}

.aboutus-tile-photo {
    width: 50%;
    height: auto;
}

.aboutus-tile-big {
    font-family: Raleway;
    font-weight: 800;
    font-size: 2rem;
}

.aboutus-tile-text {
    margin-top: 20px;
    font-family: Raleway;
    font-weight: 600;
    font-size: 1rem;
    width: 60%;
}

.aboutus-tile-1 {
    grid-area: 1 / 1 / 2 / 3;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tile-2 {
    grid-area: 1 / 3 / 2 / 5;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-3 {
    grid-area: 2 / 1 / 4 / 3;
    background-image: url(../../static/media/tilephoto1.a5147a37.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.aboutus-tile-4 {
    grid-area: 2 / 3 / 3 / 4;
    background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.aboutus-tile-5 {
    grid-area: 2 / 4 / 3 / 5;
    background: linear-gradient(180deg, #0376DF 0%, #004687 100%);
}
.aboutus-tile-6 {
    grid-area: 3 / 3 / 4 / 4;
    background: linear-gradient(180deg, #969595 0%, #656565 100%);
}
.aboutus-tile-7 {
    grid-area: 4 / 1 / 5 / 2;
    background: linear-gradient(180deg, #404040 0%, #1E1D1D 100%);
}
.aboutus-tile-8 {
    grid-area: 4 / 2 / 5 / 4;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-9 {
    grid-area: 3 / 4 / 5 / 5;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tiles-second {
    width: 100%;
    height: 40vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.aboutus-tile-second1 {
    grid-area: 1 / 1 / 3 / 2;
    background: linear-gradient(180deg, #404040 0%, #1E1D1D 100%);
}
.aboutus-tile-second2 {
    grid-area: 3 / 1 / 4 / 2;
    background: linear-gradient(180deg, #969595 0%, #656565 100%);
}
.aboutus-tile-second3 {
    grid-area: 1 / 2 / 2 / 3;
    background: linear-gradient(180deg, #A5D4FF 0%, #5293CE 100%);
}
.aboutus-tile-second4 {
    grid-area: 2 / 2 / 4 / 3;
    background: linear-gradient(180deg, #C80C12 0%, #7E0004 100%);
}
.aboutus-tile-second5 {
    grid-area: 3 / 3 / 4 / 5;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
}
.aboutus-tile-second6 {
    grid-area: 1 / 3 / 3 / 5;
    background-image: url(../../static/media/tilephoto2.05b673d2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 1100px) {
    .aboutus-section-wrapper {
        width: 100%;
        padding: 10px;
    }
    .aboutus-description {
        width: 100%;
    }
    .aboutus-tiles-wrapper {
        width: 100%;
    }
    .aboutus-tiles {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    .aboutus-tiles-second {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    .aboutus-tile_first-section {
        font-size: 1.5rem;
        padding: 10px;
    }
    .aboutus-tile-text {
        font-size: 0.7rem;
        width: 100%;
    }
}
.navbar {
  background-color: #036CCD;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 80px;
  width: 90%;
  max-width: 1800px;
}

.navbar-logo {
  background: url(../../static/media/logo_parking.a4ab281e.png) no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 10px;
  aspect-ratio: 1 / 1;
  width: 20%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s opacity;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  text-wrap: nowrap;
}

.nav-links {
  font-family: Raleway;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    overflow: visible;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

