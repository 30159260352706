.prices-wrapper {
    padding: 50px;
    background: #D8ECFF;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
}
.wavestop-prices {
    background: #FFFFFF;
    width: 100%;
}
.wavestop-prices svg{
    margin-bottom: -5px;
    transform:scale(-1,1);}
.prices-title {
    width: 100%;
    text-align: center;
}
.prices-title h3 {
    color: #003A6E;
    font-size: 2rem;
    font-weight: 600;
    font-family: Raleway;
}
.prices-table {
    padding: 20px;
    color: white;
    background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
    width: 60%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
}
.prices-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 5px auto;
}
.prices-item {
    padding: 10px;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid whitesmoke;
    border-radius: 50px;
}
.prices-highlight {
    border: 2px solid white;
    font-weight: bold;
}
.prices-item div {
    font-family: Raleway;
}
.prices-row-alt {
    padding: 10px;
    width: 90%;
    margin: 5px auto;
    border: 1px solid whitesmoke;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
}
.prices-item-alt {
    font-weight: 400;
    font-family: Raleway;
}
.prices-description {
    width: 80%;
    text-align: center;
    margin: auto;
}
.prices-description-bold {
    font-weight: 600;
    font-family: Raleway;
    font-size: 1.5rem;
    color: #003A6E;
}
.prices-description-regular {
    margin: 20px auto;
    font-weight: 400;
    font-family: Raleway;
    font-size: 1.2rem;
    color: #003A6E;
}
.prices-tabs-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 20px auto;
}
.prices-table-disabled {
    display: none;
}
.price-tab {
    width: 32%;
    border: 3px solid #0056A4;
    border-radius: 100px;
    padding: 10px 30px;
    font-size: 1rem;
    font-family: Raleway;
    font-weight: 600;
    color: #0056A4;
    transition: 0.4s;
    text-align: center;
}
.price-tab:hover {
    cursor: pointer;
    background-color: #003A6E;
    border-color: #003A6E;
    color: white;
}

.price-active {
    background: #0056A4;
    color: white;
}
@media screen and (max-width: 1100px) {
    .prices-wrapper {
        width: 100%;
        padding: 1rem;
    }
    .prices-table {
        width: 100%;
    }
    .prices-row {
        flex-direction: row;
    }
    .prices-item {
        width: 90%;
        font-size: 0.8rem;
        margin: 0 5px;
    }
}