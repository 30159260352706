.reservation {
  padding: 4rem;
  background: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.reservation > h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.reservation-tabs-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 20px;
}

.reservation-tab {
  width: 48%;
  border: 3px solid #0056A4;
  border-radius: 100px;
  padding: 10px 30px;
  font-size: 1rem;
  font-family: Raleway;
  font-weight: 600;
  color: #0056A4;
  transition: 0.4s;
}

.reservation-tab:hover {
  cursor: pointer;
  background-color: #003A6E;
  border-color: #003A6E;
  color: white;
}

.reservation-active {
  background: #0056A4;
  color: white;
}

.reservation-form-wrapper {
  width: 90%;
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
}

.reservation-form-wrapper-disabled {
  display: none;
}

.reservation-form-wrapper form {
  width: 100%;
  letter-spacing: 1px;
}
.reservation-form-wrapper h3 {
  font-weight: 400;
  font-family: Raleway;
}

.reservation-form-row-alt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px;
}

.reservation-form-row {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.reservation-double-checkbox {
  width: 50%;
  text-align: left;
  padding: 10px 20px;
}
.reservation-double-checkbox label {
  font-size: 0.8rem;
}

.reservation-form-text-input, .reservation-form-select-payment {
  width: 100%;
  height: 40px;
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-family: Raleway;
  font-weight: 600;
}
.reservation-form-text-input-alt {
  width: 60%;
  margin-right: 20px;
  height: 40px;
  border: none;
  padding: 5px;
  border-radius: 5px;
}
.checkbox-flex-column {
  flex-direction: column;
  width: 100%;
  text-wrap: nowrap;
}
.reservation-form-info-text {
  color: #B7DDFD;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 300;
  font-family: Raleway;
  text-decoration: underline;
}
.reservation-form-check-label {
  font-size: 0.8rem;
  font-weight: 300;
  font-family: Raleway;
  margin-left: 10px;
}
.reservation-form-accept-label {
  font-size: 1.2rem!important;
  font-weight: 300;
  font-family: Raleway;
  margin-left: 10px;
}
#reservation-form-accept {
  transform: scale(1.5);
  margin-left: 5px;
}
.reservation-form-accept-label a {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Raleway;
}
.reservation-form-submit {
  padding: 10px 30px;
  margin: auto;
  border: 2px solid whitesmoke;
  border-radius: 50px;
  background-color: #940000;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Raleway;
  font-size: 1.4rem;
  letter-spacing: 1px;
}
.reservation-form-submit:hover {
  cursor: pointer;
  background-color: #7e0004;
}
.reservation-additional-info-disabled {
  display: none;
}
.reservation-additional-info {
  display: block;
}
.reservation-input-label {
  padding: 0 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.reservation-input-label-full {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.reservation-input-label label {
  padding: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: Raleway;
  text-wrap: nowrap;
}
.reservation-input-label-full label {
  padding: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: Raleway;
  text-wrap: nowrap;
}

#reservation-response {
  display: none;
  width: 90%;
  background: linear-gradient(180deg, #0054A0 0%, #003667 100%);
  padding: 50px 20px;
  border-radius: 15px;
  color: white;
}
#response-title {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Raleway;
}
#response-description {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 400;
  font-family: Raleway;
}
#response-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
#voucher-link {
  color: white;
  text-decoration: none;
}
.voucher-active {
  display: block;
}
.voucher-disabled {
  display: none;
}
.response-button {
  margin: 20px;
  border: 2px solid whitesmoke;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: Raleway;
  padding: 10px 30px;
  border-radius: 50px;
}
.response-button:hover {
  cursor: pointer;
}
#download-voucher {
  background-color: #940000;
}
#download-voucher:hover {
  background-color: #7E0004;
}
#refresh-reservation:hover {
  background-color: #003A6E;
}

.download-empty-voucher {
  position: relative;
  top: 50px;
  left: 290px;
}

.download-empty-voucher a {
  color: black;
}


@media screen and (max-width: 1100px) {
  .reservation {
    width: 100%;
    padding: 1rem;
  }
  .reservation-tab {
    font-size: 0.7rem;
    text-align: center;
  }
  .reservation-form-row {
     flex-direction: column;
  }
  .reservation-form-row-alt {
    flex-direction: column;
  }
  .reservation-input-label {
    width: 100%;
  }
  .reservation-form-text-input-alt {
    width: 100%;
  }
  .reservation-form-accept-label {
    font-size: 0.8rem;
  }
  .reservation-form-accept-label a {
    font-size: 0.8rem;
  }
  .reservation-form-row-regulamin {
    flex-direction: row!important;
  }
  .reservation-double-checkbox {
    width: 100%;
  }
  .reservation-double-checkbox label {
    font-size: 0.6rem;
  }
}